import { Field } from "formik";
import React, { useState} from "react";
import { Fragment } from "react";
import { processImage, uploadImage } from "../../utils/imageHelper";
import COMMON_ACTIONS from "../../store/common/actions";
import {
  IMAGE_FILE_TYPES,
  MAXIMUM_FILE_SIZE_LIMIT,
  MAXIMUM_FILE_UPLOAD_LIMIT,
  MESSAGES,
  MINIMUM_FILE_UPLOAD_LIMIT,
  FILE_UPLOAD_TYPE,
} from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import dropImageStyles from "./style.module.css";
import { useEffect } from "react";

const styles = {
  preview: {
    display: "flex",
    flexDirection: "column",
  },
  image: { maxWidth: "100%" },
  delete: {
    cursor: "pointer",
    color: "white",
    border: "none",
  },
};

const ImageComponent = ({ file, onLoad }) => {
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    if (file) {
      const objectUrl = URL.createObjectURL(file);
      console.log("object url ", objectUrl)
      setImageUrl(objectUrl);

      // Clean up the object URL when the component unmounts
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [file]); // Only re-run the effect if the file changes

  return <img src={imageUrl} alt="Preview" onLoad={onLoad} />;
};

function BasicImageFlow(props) {
  const dispatch = useDispatch();
  const { selectedImage, images } = useSelector((state) => state.common);
  const { selectedLocalmage, localImages } = useSelector(
    (state) => state.common
  );
  const [loadingImage, setLoadingImage] = useState(0);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [uploadImg, setUploadImg] = useState([]);
  const [loading, setLoading] = useState([]);

  const handleImageChange = async (event) => {
    const { files } = event.target;

    if (files && files?.length) {
      setLoadingImage(files.length);
      const fileArray = Array.from(files);
      const loadingStates = fileArray?.map((p) => true);

      setLoading((prev) => {
        const prevLoading = [...prev, ...fileArray.map((f) => true)];
        return prevLoading;
      });
      let fileImages = await Promise.all(
        fileArray.map((element) => processImage(element))
      );
      const processedImages = [];
      let uploadPromises = [];
      let errorFreeProcessedImage = [];
      const filesWithError = [];


      for (let i = 0; i < fileImages.length; i++) {
        fileImages[i].fileId = null;
        
        if (!IMAGE_FILE_TYPES.includes(fileImages[i].file.type)) {
          fileImages[i].localError = true;
          fileImages[i].localErrorMessage = MESSAGES.VALID_IMAGE_FILE_TYPE;
        } else if (!fileImages[i].is4by3) {
          fileImages[i].localError = true;
          fileImages[i].localErrorMessage = MESSAGES.FILE_UPLOAD_ASPECT_RATIO;
        } else if (fileImages[i].file.size > MAXIMUM_FILE_SIZE_LIMIT) {
          fileImages[i].localError = true;
          fileImages[i].localErrorMessage = MAXIMUM_FILE_SIZE_LIMIT;
        }

        if (!fileImages[i]?.localError) {
          if (props?.formik?.initialStatus === "add") {
            errorFreeProcessedImage.push(fileImages[i]);
            // const formData = new FormData();
            // formData.append("file", fileImages[i]?.file);
            // formData.append("type", FILE_UPLOAD_TYPE.PROPERTY);
            // const res = await uploadImage(formData);
            // console.log("Result of api ", res.data)
            // uploadPromises.push(res?.data);
            // fileImages[i].fileId = res?.data?.fileId;
          } else {
            uploadPromises.push(fileImages[i]);
          }
        } else {
          filesWithError.push(fileImages[i]);
        }
        processedImages.push(fileImages[i]);
      }

      const responseData = await Promise.all(
        errorFreeProcessedImage.map((ef) => {
          const formData = new FormData();
          formData.append("file", ef.file);
          formData.append("type", FILE_UPLOAD_TYPE.PROPERTY);
          return uploadImage(formData);
        })
      );

      let formattedUploadedFiles = responseData?.map?.((res) => {
        uploadPromises.push(res?.data);
        return res?.data;
      });
      let combinedImages = [...filesWithError, ...formattedUploadedFiles];

      console.log("...combined ", combinedImages, "uploadPromises +++ ", uploadPromises);
      setUploadImg([...uploadImg,...combinedImages]);

      // setUploadImg([...uploadImg, ...processedImages]);
      // setLoading((prev) => {
      //   const prevState = [...prev];
      //   return prevState.map((p) => false);
      // });

      const totalImages = (uploadImg?.length || 0) + fileImages.length;
      if (totalImages < MINIMUM_FILE_UPLOAD_LIMIT) {
        setError(true);
        setErrorMessage(MESSAGES.MINIMUM_UPLOAD_FILE_LIMIT);
      } else if (totalImages > MAXIMUM_FILE_UPLOAD_LIMIT) {
        setError(true);
        setErrorMessage(MESSAGES.MAXIMUM_UPLOAD_FILE_LIMIT);
      } else {
        setError(false);
        setErrorMessage("");
      }
      let selectedFiles = [];
      if (props?.formik?.initialStatus === "add") {
        selectedFiles = uploadPromises;
      } else {
        selectedFiles = uploadPromises?.map((item) => item.file);
      }
      let selectedImageCopy = selectedImage ? selectedImage : [];
      let imagesCopy = images ? images : [];

      dispatch({
        type: COMMON_ACTIONS.SET_IMAGES,
        key: "selectedImage",
        value: [...selectedImageCopy, ...processedImages],
      });
      dispatch({
        type: COMMON_ACTIONS.SET_IMAGES,
        key: "images",
        value: [...imagesCopy, ...selectedFiles],
      });
    }
  };

  const removeSpecificImage = (index, fileId) => {


    console.log("index +++ " ,index, "fileId +++ ", fileId)

    // return false
    setUploadImg(uploadImg?.filter((_, i) => i !== index));

    if (uploadImg?.length - 1 < MINIMUM_FILE_UPLOAD_LIMIT) {
      setError(true);
      setErrorMessage(MESSAGES.MINIMUM_UPLOAD_FILE_LIMIT);
    }
// console.log("images +++ ", images, " selectedImage +++ ", selectedImage)
    if (selectedImage[index]) {
      console.log( selectedImage[index]?.fileId, " selectedImage +++ ", selectedImage)
      const updatedSelectedImage = selectedImage.filter((_, i) => i !== index);

      setLoading((prev) => {
        return prev.filter((_, i) => i !== index);
      });

      dispatch({
        type: COMMON_ACTIONS.SET_IMAGES,
        key: "selectedImage",
        value: [...updatedSelectedImage], // Spread the array to ensure a new reference
      });
    }

    if (images[index]?.fileId) {
      console.log(images[index]?.fileId, " ++++images +++ ", images)
      const updatedImages = images.filter((_, i) => i !== index);
      dispatch({
        type: COMMON_ACTIONS.SET_IMAGES,
        key: "images",
        value: [...updatedImages], // Spread the array to ensure a new reference
      });
    }
  };

  const removeAllImages = () => {
    setLoading([])
    setUploadImg([]);
    dispatch({
      type: COMMON_ACTIONS.SET_IMAGES,
      key: "selectedImage",
      value: [],
    });
    dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: "images", value: [] });
    setError(true);
    setErrorMessage(MESSAGES.MINIMUM_UPLOAD_FILE_LIMIT);
  };

  return (
    <Fragment>
      <label className="mb-2">Add Image</label>
      <div className="drop-image-outer" style={styles.container}>
        <Field
          name="images"
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          className="show-for-sr"
          multiple
        />
        <span>
          <i className="fas fa-images"></i>
        </span>
      </div>

      {uploadImg?.length > 0 && (
        <div className={dropImageStyles.removeAll}>
          <button onClick={removeAllImages} style={styles.delete}>
            <p className={dropImageStyles["remove-images"]}>Remove All</p>
          </button>
        </div>
      )}

      <div
        className={`${dropImageStyles["image-pre-outer"]} ${
          uploadImg?.length || loadingImage > 0
            ? dropImageStyles["selected-images"]
            : ""
        }`}
      >
        {/* {loading.map(i=>{
          if(i){
            return  <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          }
         
        }) } */}
        {loading?.map((item, index) => (
          <div
            key={index}
            className={dropImageStyles["main-img-priew"]}
            draggable
            style={styles.preview}
          >
            {loading[index] && (
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
            {uploadImg[index]?.fileId === null ? (
              <ImageComponent
                file={uploadImg[index]?.file}
                onLoad={() => {
                  setLoading(prev=>{
                    const prevousState = [...prev];
                    prevousState[index] = false;
                    return prevousState;
                  })
                }}
              />
            ) : (
              <img
                name="hero"
                src={uploadImg[index]?.fileUrl}
                style={styles.image}
                alt="Thumb"
                onLoad={() => {
                  setLoading(prev=>{
                    const prevousState = [...prev];
                    prevousState[index] = false;
                    return prevousState;
                  })
                }}
                hidden={loading[index]}
              />
            )}
            {uploadImg[index]?.localError &&
              uploadImg[index]?.localErrorMessage && (
                <div className="error mt-2">{uploadImg[index]?.localErrorMessage}</div>
              )}
            <p
              onClick={() =>
                removeSpecificImage(index, uploadImg[index]?.fileId)
              }
            >
              X
            </p>
          </div>
        ))}
      </div>

      {error && errorMessage && (
        <div className="error mt-2">{errorMessage}</div>
      )}
    </Fragment>
  );
}

export default BasicImageFlow;
